import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './core/auth-guard.service';

declare var config: any;
var routes: Routes = [];

if (config.viewpltf && config.viewpltf == 'mobile') {
  var routes: Routes = [
    {
      path: '',
      redirectTo: 'home',
      pathMatch: 'full'
    },
    {
      path: 'home',
      loadChildren: () => import('./views/home/home.module').then(m => m.HomePageModule),
      canActivate: [AuthGuardService],
      data: {
        needAuth: false,
        sideMenu: true,
        footerMenu: true
      }
    },
    {
      path: 'getting-started',
      loadChildren: () => import('./views/getting-started/getting-started.module').then(m => m.GettingStartedPageModule),
      canActivate: [AuthGuardService],
      data: {
        sideMenu: true
      }
    },
    {
      path: 'find-near-by-me',
      loadChildren: () => import('./views/find-near-by-me/find-near-by-me.module').then(m => m.FindNearByMePageModule),
      canActivate: [AuthGuardService],
      data: {
        needAuth: false,
        sideMenu: false,
        footerMenu: false
      }
    },

    {
      path: 'login',
      loadChildren: () => import('./views/login/login.module').then(m => m.LoginPageModule),
      canActivate: [AuthGuardService],
      data: {
        needAuth: false,
        sideMenu: true,
        footerMenu: true
      }
    },
    {
      path: 'register',
      loadChildren: () => import('./views/register/register.module').then(m => m.RegisterPageModule),
      canActivate: [AuthGuardService],
      data: {
        needAuth: false,
        sideMenu: false,
        footerMenu: true
      }
    },
    {
      path: 'modals/lang',
      loadChildren: () => import('./views/modals/lang/lang.module').then(m => m.LangPageModule)
    },
    {
      path: 'modals/cart',
      loadChildren: () => import('./views/modals/cart/cart.module').then(m => m.CartPageModule)
    },
    {
      path: 'modals/toppings',
      loadChildren: () => import('./views/modals/toppings/toppings.module').then(m => m.ToppingsPageModule)
    },
    {
      path: 'modals/cross-selling',
      loadChildren: () => import('./views/modals/cross-selling/cross-selling.module').then(m => m.CrossSellingPageModule)
    },
    {
      path: 'modals/table-reservation',
      loadChildren: () => import('./views/modals/table-reservation/table-reservation.module').then(m => m.TableReservationPageModule)
    },
    {
      path: 'modals/select-order-type',
      loadChildren: () => import('./views/modals/select-order-type/select-order-type.module').then(m => m.SelectOrderTypePageModule)
    },
    {
      path: 'restaurant/:slug',
      loadChildren: () => import('./views/ordering-main2/ordering-main2.module').then(m => m.OrderingMain2PageModule),
      canActivate: [AuthGuardService],
      data: {
        needAuth: false,
        sideMenu: true,
        footerMenu: true
      }
    },
    {
      path: 'restaurant/review/:slug',
      loadChildren: () => import('./views/review/review.module').then(m => m.ReviewPageModule),
      canActivate: [AuthGuardService],
      data: {
        needAuth: false,
        sideMenu: true,
        footerMenu: true
      }
    },
    {
      path: 'restaurant/info/:slug',
      loadChildren: () => import('./views/restaurant-info/restaurant-info.module').then(m => m.RestaurantInfoPageModule),
      canActivate: [AuthGuardService],
      data: {
        needAuth: false,
        sideMenu: true,
        footerMenu: true
      }
    },
    {
      path: 'verify-mobile',
      loadChildren: () => import('./views/verify-otp/verify-otp.module').then(m => m.VerifyOTPPageModule),
      canActivate: [AuthGuardService],
    },

    {
      path: 'branches-map',
      loadChildren: () => import('./views/branches-map/branches-map.module').then(m => m.BranchesMapPageModule),
      canActivate: [AuthGuardService],
      data: {
        needAuth: false,
        sideMenu: true,
        footerMenu: true
      }
    },
    {
      path: 'pages/:slug',
      loadChildren: () => import('./views/pages/pages.module').then(m => m.PagesPageModule)
    },
    {
      path: 'restaurant-list',
      loadChildren: () => import('./views/restaurant-list/restaurant-list.module').then(m => m.RestaurantListPageModule),
      canActivate: [AuthGuardService],
      data: {
        needAuth: false,
        sideMenu: true,
        footerMenu: true
      }
    },
    {
      path: 'checkout',
      loadChildren: () => import('./views/checkout/checkout.module').then(m => m.CheckoutPageModule),
      canActivate: [AuthGuardService],
      data: {
        needAuth: false,
        sideMenu: true,
        footerMenu: true
      }
    },
    {
      path: 'alert',
      loadChildren: () => import('./views/modals/alert/alert.module').then(m => m.AlertPageModule)
    },
    {
      path: 'profile',
      loadChildren: () => import('./views/profile-update/profile-update.module').then(m => m.ProfileUpdatePageModule),
      canActivate: [AuthGuardService],
      data: {
        needAuth: false,
        sideMenu: true,
        footerMenu: true
      }
    }, {
      path: 'forgot-password',
      loadChildren: () => import('./views/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule),
      canActivate: [AuthGuardService],
      data: {
        needAuth: false,
        sideMenu: true,
        footerMenu: true
      }
    },
    {
      path: 'my-orders',
      loadChildren: () => import('./views/past-order/past-order.module').then(m => m.PastOrderPageModule),
      canActivate: [AuthGuardService],
      data: {
        needAuth: false,
        sideMenu: true,
        footerMenu: true
      }
    }, {
      path: 'branches-map/:slug/review',
      loadChildren: () => import('./views/restaurant-review/restaurant-review.module').then(m => m.RestaurantReviewPageModule),
      canActivate: [AuthGuardService],
      data: {
        needAuth: false,
        sideMenu: true,
        footerMenu: true
      }
    },
    {
      path: 'order-summery/:unique',
      loadChildren: () => import('./views/order-summery/order-summery.module').then(m => m.OrderSummeryPageModule),
      canActivate: [AuthGuardService],
      data: {
        needAuth: false,
        sideMenu: true,
        footerMenu: true
      }
    },
    {
      path: 'delivery-method',
      loadChildren: () => import('./views/delivery-method/delivery-method.module').then(m => m.DeliveryMethodPageModule),
      canActivate: [AuthGuardService],
      data: {
        needAuth: false,
        sideMenu: true,
        footerMenu: true
      }
    },
    {
      path: 'my-products/:slug',
      loadChildren: () => import('./views/my-products/my-products.module').then(m => m.MyProductsPageModule),
      canActivate: [AuthGuardService],
      data: {
        needAuth: false,
        sideMenu: true,
        footerMenu: true
      }
    },
    {
      path: 'coupons',
      loadChildren: () => import('./views/promotions/promotions.module').then(m => m.PromotionsPageModule),
      canActivate: [AuthGuardService],
      data: {
        needAuth: false,
        sideMenu: true,
        footerMenu: true
      }
    },
    {
      path: 'actions',
      loadChildren: () => import('./views/actions/actions.module').then(m => m.ActionsPageModule),
      canActivate: [AuthGuardService],
      data: {
        needAuth: false,
        sideMenu: true,
        footerMenu: true
      }
    },
    {
      path: 'my-products/:slug/category/:category/:category_name',
      loadChildren: () => import('./views/products-list/products-list.module').then(m => m.ProductsListPageModule),
      canActivate: [AuthGuardService],
      data: {
        needAuth: false,
        sideMenu: true,
        footerMenu: true
      }
    },
    {
      path: 'imprint/:slug',
      loadChildren: () => import('./views/imprint/imprint.module').then(m => m.ImprintPageModule),
      canActivate: [AuthGuardService],
      data: {
        needAuth: false,
        sideMenu: true,
        footerMenu: true
      }
    },
    {
      path: 'open-hours/:restaurant_id',
      loadChildren: () => import('./views/open-hours/open-hours.module').then(m => m.OpenHoursPageModule),
      canActivate: [AuthGuardService],
      data: {
        needAuth: false,
        sideMenu: true,
        footerMenu: true
      }
    },
    {
      path: 'register-via',
      loadChildren: () => import('./views/register-via/register-via.module').then(m => m.RegisterViaPageModule),
      canActivate: [AuthGuardService],
      data: {
        needAuth: false,
        sideMenu: true,
        footerMenu: true
      }
    },
    {
      path: 'additives-and-substances',
      loadChildren: () => import('./views/additives-and-substances/additives-and-substances.module').then(m => m.AdditivesAndSubstancesPageModule),
      canActivate: [AuthGuardService],
      data: {
        needAuth: false,
        sideMenu: true,
        footerMenu: true
      }
    }

  ];
}

if (config.viewpltf && config.viewpltf == 'web') {
  var routes: Routes = [
    {
      path: '',
      redirectTo: 'home',
      pathMatch: 'full'
    },
    {
      path: 'home',
      loadChildren: () => import('./views/web-views/weblanding/weblanding.module').then(m => m.WeblandingPageModule),
      canActivate: [AuthGuardService],
      data: {
        needAuth: false,
        sideMenu: false,
        footerMenu: false
      }
    },
    {
      path: 'restaurants',
      loadChildren: () => import('./views/web-views/restaurants/restaurants.module').then(m => m.RestaurantsPageModule),
      data: {
        needAuth: false,
        sideMenu: false,
        footerMenu: true
      }
    },
    {
      path: 'menu/:slug',
      loadChildren: () => import('./views/web-views/web-menu/web-menu.module').then(m => m.WebMenuPageModule),
      data: {
        needAuth: false,
        sideMenu: false,
        footerMenu: true
      }
    }
  ];
}

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
