import { Component } from '@angular/core';
import { Platform, ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { AppServiceService } from './api/app-service.service';
import { CallNumber } from '@ionic-native/call-number/ngx';

//For One Signal Fix
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { RequestService } from 'src/app/core/request.service';
import { ModalPopoverPage } from "./views/web-views/shared/modal-popover/modal-popover.page";

declare var config: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  public viewType: any;

  public pages: any = {};

  public PRIVACY_POLICY_SLUG = 'pnp';
  public TERMS_AND_CONDITIONS_SLUG = 'tnc';

  constructor(
    public appService: AppServiceService,
    private platform: Platform,
    private callNumber: CallNumber,
    public splashScreen: SplashScreen,
    public statusBar: StatusBar,
    public oneSignal: OneSignal,
    public requestService: RequestService,
    public router: Router,
    private modalController: ModalController
  ) {
    //For One Signal Fix
    this.initializeApp();
  }

  async initializeApp() {
    this.platform.ready().then(() => {
      
      this.splashScreen.show();
      this.appService.appInit();

      this.statusBar.overlaysWebView(true);
      setTimeout(() => {
        this.splashScreen.hide();
      }, 3000);

      this.requestService.post('onesignal/key?device_type=' + config.plf, []).then(data => {
        if (data.status) {

          //set one signal id for web to init
          config.osid = data.onesignal_key;
          this.appService.appObject.oneSignalConfig.key = data.onesignal_key;
          this.appService.appObject.oneSignalConfig.fireid = data.firebase_id;

          //For One Signal Fix
          if ((this.platform.is('android') || this.platform.is('ios')) && !this.appService.isMobileWeb()) {
            this.oneSignal.startInit(this.appService.appObject.oneSignalConfig.key, this.appService.appObject.oneSignalConfig.fireid);
            this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.None);
            this.oneSignal.handleNotificationReceived().subscribe(data => {
              // do something when notification is received 
            });
            this.oneSignal.handleNotificationOpened().subscribe(data => {
              // do something when a notification is opened
            });
            this.oneSignal.endInit();

            this.oneSignal.getIds().then(identity => {
              //this.appService.showAlert('success','success',JSON.stringify(identity));
              this.appService.appObject.deviceUUID = identity.userId;
              //call device registration again to store device id which from one signal
              this.doUpdate();
            }, err => {
              //this.appService.showAlert('error','error',err);
            });

          }

        }
      });

    });
  }

  call() {
    this.callNumber.callNumber(this.appService.appObject.selectedRestaurantDetails.owner_mobile_number, true)
      .then(res => console.log('Launched dialer!', res))
      .catch(err => console.log('Error launching dialer', err));
  }

  openProfile() {
    if (this.appService.appObject.isAuthed) {
      this.router.navigate(['/profile']);
    } else {
      this.router.navigate(['/getting-started']);
    }
  }

  async doUpdate() {
    // console.log('DO_UPDATEEEEE');
    await this.appService.deviceRegister(true);
    await this.appService.updateAppObject();
  }

  cookiesAccepted() {
    this.appService.appObject.isCookiesAccepted = true;
    this.appService.updateAppObject();
  }

  cookiesBannerDismissed() {
    let cookiesBanner = document.getElementById('cookies-banner');
    if (cookiesBanner) {
      cookiesBanner.remove();
    }
  }

  displayCookieBanner() {
    if ((this.appService.isWebVersion || ((this.platform.is('android') || this.platform.is('ios')) && this.appService.isMobileWeb())) && (this.appService.appObject && !this.appService.appObject.isCookiesAccepted)) {
      return true;
    }
    return false;
  }

  displayLinks(slug) {
    if (this.appService.appObject && this.appService.appObject.settings && this.appService.appObject.settings.pages) {
      if (this.appService.appObject.settings.pages.length) {
        // console.log('this.appService.appObject.currentLang : ', slug + '-' + this.appService.appObject.currentLang);
        let page = this.appService.appObject.settings.pages.find((page) => page.slug === (slug + '-' + this.appService.appObject.currentLang) && page.status === 'true')
        if (page) {
          this.pages[slug] = page;
          return true;
        }
        else return false;
      }
    }
  }

  async openSlug(slug, title) {
    if (this.appService.isWebVersion) {
      const modal = await this.modalController.create({
        component: ModalPopoverPage,
        cssClass: 'order-popup',
        componentProps: {
          'type': 'pages',
          'title': title,
          'slug': (slug + '-' + this.appService.appObject.currentLang)
        }
      });

      await modal.present();
    } else {
      this.router.navigate(['pages/' + (slug + '-' + this.appService.appObject.currentLang)]);
    }
  }

  isLoginPagePopup(){
    let url = new URL(window.location.href);
    // console.log('Is Login : ' , url.pathname);
    return url.pathname.trim() === '/getting-started'
  }

}
